import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { GlobalProvider } from "../providers/global/global";
import { Storage } from '@ionic/storage';
import { TranslateService } from '@ngx-translate/core';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { IdleService } from './services/idle.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    public global: GlobalProvider,
    private idleService: IdleService
  ) {
    this.initializeApp();
  }

  ngOnInit() {
    this.idleService.startWatching();
  }

  initializeApp() {
    
    // Make sure config is loaded first
    Promise.all([
      this.storage.get('usertoken').then((val) => {
        if (val != null) {
          this.global.usertoken = val;
        }
        //console.log("usertoken is " + this.global.usertoken);
      }),
      this.storage.get('preferredlang').then((val) => {
        if (val != null) {
          this.global.preferredlang = val;
        } else {
          this.global.preferredlang = "en";
        }
      }),
      this.platform.ready()
    ]
    ).then(() => {
    // Okay, so the platform is ready and our plugins are available.
    // Here you can do any higher level native things you might need.
    this.statusBar.styleDefault();
    this.splashScreen.hide();
    });
    
  }
}
